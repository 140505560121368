/**
 * 格式化时间
 * @param date 时间
 * @param fmt 格式化类型
 * @returns {*} 格式化后的时间
 */
const fmtDate = (date, fmt) => {
    let o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return fmt;
};

/**
 * 日期数据转为数字时间戳
 * @param time 时间
 * @param fmt 格式化方式序号
 * @returns {*} 格式化后的时间
 */
const dateFormatFun = (time, fmt = 1) => {
    time = new Date(time).getTime()
    if (time > 0) {
        switch (fmt) {
            case 1:
                return fmtDate(new Date(time), 'yyyy-MM-dd');
            case 2:
                return fmtDate(new Date(time), 'yyyy-MM-dd hh:mm');
            case 3:
                return fmtDate(new Date(time), 'MM月dd日 hh:mm');
            case 4:
                return fmtDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
            case 5:
                return fmtDate(new Date(time), 'hh:mm');
            case 6:
                return fmtDate(new Date(time), 'hh:mm:ss');
            case 7:
                return fmtDate(new Date(time), 'yyyy年MM月dd日');
            case 8:
                return fmtDate(new Date(time), 'yyyy.MM.dd');
            case 9:
                return fmtDate(new Date(time), 'yyyy/MM/dd');
            default:
                return fmtDate(new Date(time), 'yyyy-MM-dd');
        }
    }
};

export default dateFormatFun;