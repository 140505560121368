<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据审核</el-breadcrumb-item>
      <el-breadcrumb-item>数据审核</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <!-- 施工主管 进度表-->
      <div class="progress" v-if="roleName == '施工主管'">
        <el-row>
          <el-col :span="17"><span class="title">施工主管-进度表</span></el-col>
          <el-col :span="7">
            <el-input
              placeholder="请输入名称"
              v-model="proQuery.query"
              @keyup.enter="getProgress()"
              clearable
              @clear="getProgress()"
            >
              <template #append
                ><el-button
                  icon="el-icon-search"
                  @click="getProgress()"
                ></el-button
              ></template>
            </el-input>
          </el-col>
        </el-row>
        <el-table :data="progress" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column
            label="进度单编号"
            prop="progressNo"
          ></el-table-column>
          <el-table-column label="起桩号" prop="beginPile"></el-table-column>          
          <el-table-column label="止桩号" prop="endPile"></el-table-column>
          <el-table-column
            label="本次完成工程量"
            prop="completeQuantity"
          ></el-table-column>
          <el-table-column
            label="累计完成工程量"
            prop="completedQuantity"
          ></el-table-column>
          <el-table-column
            label="累计完成百分比"
            prop="percent"
          ></el-table-column>
          <el-table-column label="项目编号" prop="itemNo"></el-table-column>
          <el-table-column label="项目名称" prop="itemName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"
            ><template v-slot="scope"
              ><span>{{ DateFormat(scope.row.createTime) }}</span></template
            ></el-table-column
          >
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>

          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="acceptPro(scope.row)"
                >通过</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-edit"
                size="mini"
                @click="rejectPro(scope.row)"
                >不通过</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="proSizeChange"
            @current-change="proCurChange"
            :current-page="proQuery.pagenum"
            :page-sizes="[5, 10, 20]"
            :page-size="proQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="proTotal"
          >
          </el-pagination>
        </el-config-provider>
        <el-dialog
          title="数据审核"
          v-model="rejProDiaVisible"
          width="50%"
          @close="rejProDiaClosed"
        >
          <el-form
            ref="rejProForm"
            :model="curPro"
            label-width="80px"
            width="100%"
          >
            <el-form-item label="驳回原因" prop="reason"
              ><el-input type="text" v-model="curPro.reason"></el-input
            ></el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejProDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejPro">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 实验主管 试验检测 -->
      <div class="experiment" v-if="roleName == '试验主管'">
        <el-row>
          <el-col :span="17"
            ><span class="title">实验主管-试验检测表</span></el-col
          >
          <el-col :span="7">
            <el-input
              placeholder="请输入试验名称"
              v-model="expQuery.query"
              @keyup.enter="getExperiment()"
              clearable
              @clear="getExperiment()"
            >
              <template #append
                ><el-button
                  icon="el-icon-search"
                  @click="getExperiment()"
                ></el-button
              ></template>
            </el-input>
          </el-col>
        </el-row>
        <el-table :data="experiment" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="检测项目" prop="testItem"></el-table-column>
          <el-table-column label="现场进度工程量" prop="progressQuantity"
            ><template v-slot="scope"
              ><span>{{
                scope.row.itemName ? scope.row.itemName : "无"
              }}</span></template
            ></el-table-column
          >
          <el-table-column label="上报时间" prop="createTime"
            ><template v-slot="scope"
              ><span>{{ DateFormat(scope.row.createTime) }}</span></template
            ></el-table-column
          >
          <el-table-column
            label="实际进度工程量"
            prop="actualRequiredQuantity"
          ></el-table-column>
          <el-table-column
            label="取消检测"
            prop="obtainedQuantity"
            v-slot="scope"
            >{{
              scope.row.obtainedQuantity == 1 ? "是" : "否"
            }}</el-table-column
          >
          <el-table-column
            label="是否合格？"
            prop="passQuantity"
            v-slot="scope"
            >{{ scope.row.passQuantity == 1 ? "是" : "否" }}</el-table-column
          >
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="acceptExp(scope.row)"
                >通过</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-edit"
                size="mini"
                @click="rejectExp(scope.row)"
                >不通过</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="expSizeChange"
            @current-change="expCurChange"
            :current-page="expQuery.pagenum"
            :page-sizes="[5, 10, 20]"
            :page-size="expQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="expTotal"
          >
          </el-pagination>
        </el-config-provider>
        <el-dialog
          title="数据审核"
          v-model="rejExpDiaVisible"
          width="50%"
          @close="rejExpDiaClosed"
        >
          <el-form
            ref="rejExpForm"
            :model="curExp"
            label-width="80px"
            width="100%"
          >
            <el-form-item label="驳回原因" prop="reason"
              ><el-input type="text" v-model="curExp.reason"></el-input
            ></el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejExpDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejExp">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 资料主管 资料 -->
      <div class="document" v-if="roleName == '资料主管'">
        <el-row>
          <el-col :span="17"><span class="title">资料主管-资料表</span></el-col>
          <el-col :span="7">
            <el-input
              placeholder="请输入资料名称"
              v-model="docQuery.query"
              @keyup.enter="getDocument()"
              clearable
              @clear="getDocument()"
            >
              <template #append
                ><el-button
                  icon="el-icon-search"
                  @click="getDocument()"
                ></el-button
              ></template>
            </el-input>
          </el-col>
        </el-row>
        <el-table :data="document" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="册数" prop="volume"></el-table-column>
          <el-table-column
            label="文件材料名称"
            prop="documentContentName"
          ></el-table-column>
          <el-table-column
            label="资料说明"
            prop="documentDescription"
          ></el-table-column>
          <el-table-column
            label="资料完成情况说明"
            prop="documentCompleteDescription"
          ></el-table-column>
          <el-table-column label="总数" prop="totalNum"></el-table-column>
          <el-table-column
            label="编制数"
            prop="organizationNum"
          ></el-table-column>
          <el-table-column
            label="监理签认数"
            prop="supervisorNum"
          ></el-table-column>
          <el-table-column
            label="设计签认数"
            prop="designNum"
          ></el-table-column>
          <el-table-column
            label="建设单位签认数"
            prop="constructionNum"
          ></el-table-column>
          <el-table-column
            label="存档原件数"
            prop="archiveOriginalNum"
          ></el-table-column>
          <el-table-column
            label="存档复印数"
            prop="archiveCopyNum"
          ></el-table-column>
          <!-- <el-table-column label="编制完成" prop="isOrganization">
            <template v-slot="scope"><span>{{scope.row.isOrganization?'是':'否'}}</span></template>
          </el-table-column>
          <el-table-column label="监理已签认" prop="isSupervisor">
            <template v-slot="scope"><span>{{scope.row.isSupervisor?'是':'否'}}</span></template>
          </el-table-column>
          <el-table-column label="设计已签认" prop="isDesign">
            <template v-slot="scope"><span>{{scope.row.isDesign?'是':'否'}}</span></template>
          </el-table-column>
          <el-table-column label="建设单位已签认" prop="isConstruction">
            <template v-slot="scope"><span>{{scope.row.isConstruction?'是':'否'}}</span></template>
          </el-table-column>
          <el-table-column label="已移交档案馆" prop="isArchives">
            <template v-slot="scope"><span>{{scope.row.isArchives?'是':'否'}}</span></template>
          </el-table-column> -->
          <el-table-column label="上报时间" prop="createTime"
            ><template v-slot="scope"
              ><span>{{ DateFormat(scope.row.createTime) }}</span></template
            ></el-table-column
          >
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="acceptDoc(scope.row)"
                >通过</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-edit"
                size="mini"
                @click="rejectDoc(scope.row)"
                >不通过</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="docSizeChange"
            @current-change="docCurChange"
            :current-page="docQuery.pagenum"
            :page-sizes="[5, 10, 20]"
            :page-size="docQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="docTotal"
          >
          </el-pagination>
        </el-config-provider>
        <el-dialog
          title="数据审核"
          v-model="rejDocDiaVisible"
          width="50%"
          @close="rejDocDiaClosed"
        >
          <el-form
            ref="rejDocForm"
            :model="curDoc"
            label-width="80px"
            width="100%"
          >
            <el-form-item label="驳回原因" prop="reason"
              ><el-input type="text" v-model="curDoc.reason"></el-input
            ></el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejDocDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejDoc">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import dateFormatFun from "../../plugins/dateFormat";
export default {
  data() {
    return {
      progress: [],
      proQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      proTotal: 0,
      rejProDiaVisible: false,
      curPro: {},

      experiment: [],
      expQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      expTotal: 0,
      rejExpDiaVisible: false,
      curExp: {},

      document: [],
      docQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      docTotal: 0,
      rejDocDiaVisible: false,
      curDoc: {},

      roleName: "",
    };
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    this.roleName = window.sessionStorage.getItem("roleName");

    if (this.roleName == "资料主管") {
      this.getDocument();
    }
    if (this.roleName == "试验主管") {
      this.getExperiment();
    }
    if (this.roleName == "施工主管") {
      this.getProgress();
    }
  },
  computed: {
    DateFormat() {
      return function (date) {
        return dateFormatFun(date, 2);
      };
    },
  },
  methods: {
    // 进度表
    async getProgress() {
      this.proQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "progressSupervisor/getReviewProgress",
        { params: this.proQuery }
      ); // --------------
      if (res.code !== 200) return this.$message.error(res.msg);
      console.log(res.data.list, 222);
      this.progress = res.data.list;
      this.proTotal = res.data.total;
    },
    proSizeChange(newSize) {
      this.proQuery.pagesize = newSize;
      this.getprogress();
    },
    proCurChange(newPage) {
      this.proQuery.pagenum = newPage;
      this.getprogress();
    },
    async acceptPro(progress) {
      const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => console.log(err));
      if (confirmRes !== "confirm") {
        return this.$message.info("取消通过");
      }
      let formData = new FormData();
      formData.append("id", progress.id);
      const { data: res } = await this.$http.post(
        "progressSupervisor/acceptProgress",
        formData
      ); //-------------
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.getProgress();
    },
    rejectPro(progress) {
      this.curPro = progress;
      this.rejProDiaVisible = true;
    },
    rejProDiaClosed() {
      this.$refs.rejProForm.resetFields();
    },
    async rejPro() {
      let formData = new FormData();
      formData.append("id", this.curPro.id);
      formData.append("reason", this.curPro.reason);
      const { data: res } = await this.$http.post(
        "progressSupervisor/rejectProgress",
        formData
      ); //------------
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.rejProDiaVisible = false;
      this.getProgress();
    },

    // 试验
    async getExperiment() {
      this.expQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "experimentSupervisor/getReviewExperiment",
        { params: this.expQuery }
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.experiment = res.data.list;
      this.expTotal = res.data.total;
      // if(this.expTotal != 0){
      //   this.$message.info("您有试验数据待审核")
      // }
    },
    expSizeChange(newSize) {
      this.expQuery.pagesize = newSize;
      this.getExperiment();
    },
    expCurChange(newPage) {
      this.expQuery.pagenum = newPage;
      this.getExperiment();
    },
    async acceptExp(experiment) {
      const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => console.log(err));
      if (confirmRes !== "confirm") {
        return this.$message.info("取消通过");
      }
      // 通过请求只需 传参id
      let formData = new FormData();
      formData.append("id", experiment.id);
      const { data: res } = await this.$http.post(
        "experimentSupervisor/acceptExperiment",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.getExperiment();
    },
    rejectExp(experiment) {
      // 弹出对话框，填写拒绝原因
      this.curExp = experiment;
      this.rejExpDiaVisible = true;
    },
    rejExpDiaClosed() {
      this.$refs.rejExpForm.resetFields();
    },
    async rejExp() {
      let formData = new FormData();
      formData.append("id", this.curExp.id);
      formData.append("reason", this.curExp.reason);
      const { data: res } = await this.$http.post(
        "experimentSupervisor/rejectExperiment",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.rejExpDiaVisible = false;
      this.getExperiment();
    },

    // 资料
    async getDocument() {
      this.docQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "documentSupervisor/getReviewDocument",
        { params: this.docQuery }
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.document = res.data.list;
      this.docTotal = res.data.total;
    },
    docSizeChange(newSize) {
      this.docQuery.pagesize = newSize;
      this.getDocument();
    },
    docCurChange(newPage) {
      this.docQuery.pagenum = newPage;
      this.getDocument();
    },
    async acceptDoc(document) {
      const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => console.log(err));
      if (confirmRes !== "confirm") {
        return this.$message.info("取消通过");
      }
      let formData = new FormData();
      formData.append("id", document.id);
      const { data: res } = await this.$http.post(
        "documentSupervisor/acceptDocument",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.getDocument();
    },
    rejectDoc(document) {
      this.curDoc = document;
      this.rejDocDiaVisible = true;
    },
    rejDocDiaClosed() {
      this.$refs.rejDocForm.resetFields();
    },
    async rejDoc() {
      let formData = new FormData();
      formData.append("id", this.curDoc.id);
      formData.append("reason", this.curDoc.reason);
      const { data: res } = await this.$http.post(
        "documentSupervisor/rejectDocument",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.rejDocDiaVisible = false;
      this.getDocument();
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;
  color: #666;
  display: block;
  padding: 10px 0 0 2px;
}
</style>
